import * as types from './types';
import { getAgentsCharacter, getAgentsCharacters } from './actions';
import { removeDialog } from '../dialogs/actions';

const agentMiddleware = (store) => (next) => (action) => {
  const { type } = action;

  if ([
    types.UPDATE_AGENTS_CHARACTER_SUCCESS,
    types.ADD_AGENTS_CHARACTER_SUCCESS,
    types.UPDATE_AGENTS_SUCCESS,
    types.UPDATE_RAGS_SUCCESS,
  ].includes(type)) {
    store.dispatch(getAgentsCharacter(action.filter.name));
    store.dispatch(removeDialog());
  }

  if ([
    types.ORDER_AGENTS_CHARACTERS_SUCCESS,
    types.ORDER_AGENTS_GROUPS_SUCCESS,
    types.ADD_AGENTS_GROUPS_CHARACTER_SUCCESS,
    types.ADD_AGENTS_TO_GROUPS_CHARACTER_SUCCESS,
    types.REMOVE_AGENTS_CHARACTER_SUCCESS,
    types.REMOVE_AGENTS_GROUPS_CHARACTER_SUCCESS
  ].includes(type)) {
    store.dispatch(getAgentsCharacters());
  }



  return next(action);
};

export default agentMiddleware;


